import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import './App.css';
import messages from './locale/translations/index';
import Routes from './routes';
import store from './services/redux/store';
import { ConfigProvider } from 'antd';
import ConnectionProvider from './context/ConnectionContext/provider';

function App() {
  return (
    <Provider store={store}>
      <IntlProvider
        locale={navigator.language}
        messages={messages(navigator.language)}
      >
        <ConfigProvider
          theme={{
            token: {
              fontFamily: "'Acciona Font', sans-serif!important",
              colorFillAlter: 'rgb(241, 241, 241)',
              colorPrimary: '#ff0000',
              itemColor: 'white',
            },
          }}
        >
          <ConnectionProvider>
            <Routes />
          </ConnectionProvider>
        </ConfigProvider>
      </IntlProvider>
    </Provider>
  );
}

export default App;
