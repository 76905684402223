import { useEffect, useState } from 'react';

export const calculatePercent = (value, total) => {
  const calculatedValue = Math.ceil((value / total) * 1000) / 10;
  if (isNaN(calculatedValue) || calculatedValue === Infinity) return 0;
  return roundDec(calculatedValue);
};
export const roundDec = (n) => {
  return Number(n.toFixed(1));
};

export const SimpleBar = ({ width, values }) => {
  const border = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    borderRadius: '5px',
  };

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent(calculatePercent(values[1], values[0]));
  }, [values]);

  return (
    <div className="card" style={{ minHeight: '0px' }}>
      <div className="card-wrapper-table" style={{ minWidth: width }}>
        <span style={{ width: '0%', display: 'block' }}></span>
        <div
          className="total-progress"
          style={{
            display: 'flex',
            width: '100.0%',
            fontSize: '20px',
            fontWeight: '600',
            padding: 0,
            ...border,
          }}
        >
          <div
            style={{
              width: `${percent > 100 ? 100 : percent}%`,
              backgroundColor: 'red',
              display: 'grid',
              // animationDuration: `${(percent > 100 ? 100 : percent) / 50}s`,
            }}
          >
            <span className="percent-text">{`${percent}%`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
