import { useCallback, useEffect, useRef, useState } from 'react';
import AccionaInput from '../../../../../components/AccionaInput';
import Step from '../../../../../components/Step';
import AccionaFormItem from '../../../../../components/AccionaFormItem';
import { listDeliveryNote } from '../../../../../services/api/deliveryNote';
import { useNavigate, useParams } from 'react-router-dom';
import { getPreDeliveryNoteCemex } from '../../../../../services/api/preDeliveryNote';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { listPreDeliveryNote } from '../../../../../services/api/preDeliveryNote';

const DocketNumber = ({ onFinish, preregister, stepNumber }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { model, id: pid } = useParams();
  const [docketNumber, setDocketNumber] = useState(preregister.docket_number);
  const [error, setError] = useState();
  const [dataFromCemex, setDataFromCemex] = useState({});
  const [loading, setLoading] = useState(false);
  const [verifiedDocketNumber, setVerifiedDocketNumber] = useState(false);
  const validationTimeout = useRef();

  const checkCemexPreDeliveryNote = useCallback(() => {
    const date = moment();
    const formattedDate = date.format('YYYY-MM-DD');

    if (navigator.onLine) {
      setLoading(true);
      getPreDeliveryNoteCemex({
        docketNumber: docketNumber,
        date: formattedDate,
      })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            const cemexData = response.data;
            delete cemexData.docket_number;
            setDataFromCemex(() => ({ ...cemexData }));
            setVerifiedDocketNumber(true);
          } else {
            setError(() =>
              intl.formatMessage(
                { id: 'preregister.docket_number_not_found' },
                {
                  docketNumber: docketNumber,
                  date: formattedDate,
                },
              ),
            );
          }
        })
        .catch(() => {
          setLoading(false);
          setError(() =>
            intl.formatMessage(
              { id: 'preregister.docket_number_not_found' },
              {
                docketNumber: docketNumber,
                date: formattedDate,
              },
            ),
          );
        });
    }
  }, [docketNumber, intl]);

  const checkDuplicateDocketNumber = useCallback(() => {
    setLoading(true);
    listDeliveryNote(`project=${pid}&docket_number=${docketNumber}`).then(
      (result) => {
        if (result?.data?.delivery_notes?.length > 0) {
          setError(intl.formatMessage({ id: 'docket_number.inDB' }));
          setLoading(false);
        } else {
          listPreDeliveryNote({
            size: 5,
            page: 1,
            filters: { pid: pid, docket_number: docketNumber },
          }).then((result) => {
            if (result?.data?.data?.length > 0) {
              setError(intl.formatMessage({ id: 'docket_number.inDB' }));
            } else {
              setVerifiedDocketNumber(true);
            }
            setLoading(false);
          });
        }
      },
    );
  }, [docketNumber, intl, pid]);

  useEffect(() => {
    if (validationTimeout.current) {
      clearTimeout(validationTimeout.current);
    }

    if (docketNumber) {
      setVerifiedDocketNumber(false);
      setError(undefined);
      validationTimeout.current = setTimeout(() => {
        if (model === 'cemex') {
          checkCemexPreDeliveryNote();
        } else {
          checkDuplicateDocketNumber();
        }
      }, 1000);
    }
  }, [
    docketNumber,
    pid,
    checkDuplicateDocketNumber,
    model,
    checkCemexPreDeliveryNote,
  ]);

  return (
    <Step
      stepNumber={stepNumber}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step01',
      })}
      content={
        <>
          <AccionaFormItem style={error ? { marginBottom: '5px' } : undefined}>
            <AccionaInput
              type="number"
              label="docket_number"
              onChange={(inputValue) => {
                if (inputValue.length < 11) {
                  setDocketNumber(inputValue);
                }
              }}
              state={{
                value: docketNumber,
                status: error ? 'error' : 'success',
              }}
              loading={loading}
              verified={verifiedDocketNumber}
            />
          </AccionaFormItem>
          <div className={`${error ? 'infoError' : ''}`}>{error}</div>
        </>
      }
      onBackButtonClick={() => {
        navigate('../../');
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          ...dataFromCemex,
          docket_number: docketNumber,
        });
      }}
      disableNextButton={Boolean(
        error || !docketNumber || loading || !verifiedDocketNumber,
      )}
    />
  );
};

DocketNumber.propTypes = {
  preregister: PropTypes.shape({
    docket_number: PropTypes.number,
  }),
  onFinish: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

DocketNumber.defaultProps = {
  preregister: {},
};

export default DocketNumber;
