import React from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import Layout from '../Layout';
import Project from '../pages/Project';
import Login from '../pages/Login';
import PreRegisterWizard from '../pages/PreRegisterWizard';
import PreDNList from '../pages/PreRegistersList';
import Projects from '../pages/Projects';
import ProtectedComponent from '../pages/Utils/ProtectedComponent';

function Router() {
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/app',
      element: <Layout />,
      children: [
        {
          path: 'projects',
          element: <Projects />,
        },
        {
          path: 'project/:id',
          element: <ProtectedComponent />,
          children: [
            {
              path: '',
              element: <Project />,
            },
            {
              path: 'preregisterList',
              element: <PreDNList />,
            },
            {
              path: ':model/preregister',
              element: <PreRegisterWizard />,
            },
            {
              path: '*',
              element: <Navigate to="../" replace />,
            },
          ],
        },
        {
          path: '*',
          element: <Navigate to="/app/projects" replace />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/login" replace />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default Router;
