export const DATABASE_NAME = 'OCR-PWA';
export const VERSION = 4;
export const PREREGISTER_TABLE = 'preRegister';

export const preRegisterColumns = [
  'id',
  'docket_number',
  'project',
  'arriving_time',
  'arriving_date',
  'detailed_location',
  'finish_discharging_time',
  'mash_control',
  'mash_control_extra',
  'slump',
  'specimens_number',
  'start_discharging_time',
  'used',
  'elements',
  'model',
  'base64',
  'prm',
  'prm_data',
];
