import { PropTypes } from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AccionaButton from '../AccionaButton';

const RemoveModal = ({ onOk, onCancel, hidden, msg }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <div
        id="RemoveModal"
        className="modal-open modal fade in"
        tabIndex="-1"
        style={hidden ? {} : { display: 'block', marginTop: '200px' }}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body" style={{ textAlign: 'center' }}>
              <h3 style={{ marginTop: '0px' }}>
                <FormattedMessage id={msg} />
              </h3>
            </div>
            <div className="modal-footer form-row">
              <AccionaButton onClick={onOk} msg={formatMessage({ id: 'ok' })} />
              <AccionaButton
                className="btn btn-gray"
                onClick={onCancel}
                msg={formatMessage({ id: 'cancel' })}
              />
            </div>
          </div>
        </div>
      </div>
      <div hidden={hidden} className="modal-backdrop fade in"></div>
    </>
  );
};

RemoveModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  msg: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};

RemoveModal.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
  msg: 'delete',
  hidden: false,
};

export default RemoveModal;
