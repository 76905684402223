import React from 'react';

const TemplateFooter = () => {
  return (
    <div className="footer text-center">
      <span style={{ color: '#FFFFFF' }}>
        ACCIONA © 2022 All rights reserved
      </span>
    </div>
  );
};

export default TemplateFooter;
