import { Collapse } from 'antd';
import { PropTypes } from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { useNavigate, useOutletContext } from 'react-router-dom';
import AccionaBigButton from '../../components/AccionaBigButton';
import AccionaButton from '../../components/AccionaButton';
import AccionaModal from '../../components/AccionaModal';
import Modal from '../../components/AccionaModal/modalV2';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import usePrevious from '../../hooks/usePrevious';
import * as uploadActions from '../../services/redux/uploadModal/actions';
import { CE, EHE } from './normative';

const Project = ({ countOffline, total }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { project } = useOutletContext();
  const [model, setModel] = useState();
  const [showModal, setShowModal] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  const { online, setUploadModal } = useContext(ConnectionContext);
  const onAcceptMandatoryFields = () => navigate(`${model}/preregister`);
  const onCancelModal = () => {
    setShowModal(false);
    setModel(null);
  };
  const prevOnline = usePrevious(online.state);

  useEffect(() => {
    if (total > 0 && openModal === null && project.id_project && online.state) {
      setOpenModal(true);
    }
  }, [total, openModal, project.id_project, countOffline, online.state]);

  useEffect(() => {
    if (project.id_project) countOffline(project.id_project);
  }, [countOffline, project.id_project]);

  useEffect(() => {
    if (online.state === false) setOpenModal(false);
    if (online.state === true && prevOnline === false) {
      setOpenModal(null);
    }
  }, [online.state, project.id_project, countOffline, prevOnline]);

  const processedData = useMemo(() => {
    switch (project.normative_name) {
      case 'EHE':
        return EHE;
      case 'CE':
        return CE;
      default:
        return EHE;
    }
  }, [project]);

  const ContentMandatoryFieldsModal = () => {
    return (
      <>
        {model ? (
          <div
            style={{
              fontFamily: "'Acciona Font', sans-serif",
              color: '#f00',
              textTransform: 'uppercase',
            }}
          >
            <p>
              {formatMessage(
                { id: `mandatoryFieldsContent` },
                { normative: project.normative_name },
              )}
            </p>
            <div id="infoNormative">
              <Collapse
                items={[
                  {
                    key: 1,
                    label: formatMessage({ id: `more_info` }),
                    children: processedData(),
                  },
                ]}
              />
            </div>
            <div className="form-row" style={{ width: '100%' }}>
              <AccionaButton
                classIcon="icon fas fa-times"
                className="btn btn-red"
                msg={formatMessage({ id: 'no' })}
                onClick={onCancelModal}
                style={{ marginTop: '20px', width: '120px' }}
              />
              <AccionaButton
                className="btn btn-red"
                classIcon="icon fas fa-check"
                msg={formatMessage({ id: 'yes' })}
                onClick={onAcceptMandatoryFields}
                style={{ marginTop: '20px', width: '120px', float: 'right' }}
              />
            </div>
          </div>
        ) : (
          <div className="containerModelButtons">
            <AccionaButton
              className=""
              msg={formatMessage({ id: 'cemex' })}
              onClick={navigator.onLine ? () => setModel('cemex') : null}
              disabled={!navigator.onLine}
            />
            <AccionaButton
              className=""
              msg={formatMessage({ id: 'other' })}
              onClick={() => setModel('other')}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="wrapper fullscreen">
      <Modal open={Boolean(openModal)} onCancel={() => setOpenModal(false)}>
        <>
          <div className="form-row" style={{ margin: '10px' }}>
            <p>{formatMessage({ id: 'upload.pending' }, { total })}</p>
            <p>{formatMessage({ id: 'upload.questión' }, { total })}</p>
          </div>
          <div className="form-row">
            <AccionaButton
              msg={formatMessage({ id: 'upload' })}
              onClick={() => {
                setOpenModal(false);
                setUploadModal(true);
              }}
            />
            <AccionaButton
              msg={formatMessage({ id: 'cancel' })}
              onClick={() => setOpenModal(false)}
            />
          </div>
        </>
      </Modal>
      <AccionaModal
        show={showModal}
        onCancel={onCancelModal}
        title={
          model
            ? formatMessage({ id: `mandatoryFieldsTitle` })
            : formatMessage({ id: `modelTitle` })
        }
        content={<ContentMandatoryFieldsModal />}
      />
      <div className="row">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="delivery-ocr"
            id="preRegisterDocument"
            onClick={() => {
              setShowModal(true);
              setModel(null);
            }}
            msg={formatMessage({ id: `mashPreRegister` })}
          />
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4">
          <AccionaBigButton
            icon="smartphone-docs"
            id="preRegisterDocument"
            onClick={() => navigate(`preregisterList`)}
            msg={formatMessage({ id: `listPreRegister` })}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ uploadModal }) => ({
  total: uploadModal.total,
});

const matchDispachToProps = {
  countOffline: uploadActions.countPreDNOffline,
};
Project.propTypes = {
  countOffline: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};
export default connect(mapStateToProps, matchDispachToProps)(Project);
