import { PropTypes } from 'prop-types';
import { default as React } from 'react';
import { getValue } from '../../utils/utils';

const AccionaTableBody = ({ bodyData, columns, actions }) => {
  return (
    <tbody id="AccionaTableBody">
      {bodyData.map((x, index) => {
        const classContainerButtons = {
          minWidth: '140px',
          padding: '10px 6px',
          height: '94px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0px -1px -1px 0px',
        };

        return (
          <tr key={`${index}.row`} id={`${index}.row`}>
            {Object.keys(columns).map((y) => {
              return (
                <td key={`${index}.${y}`} id={`${index}.${y}`}>
                  {' '}
                  {getValue(y, x)}
                </td>
              );
            })}
            <td
              id={`${index}.actions`}
              key={`${index}.actions`}
              hidden={!actions}
              style={classContainerButtons}
            >
              {actions ? actions(x) : null}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

AccionaTableBody.propTypes = {
  bodyData: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.object,
  actions: PropTypes.func,
};

AccionaTableBody.defaultProps = {
  bodyData: [],
  columns: {},
  actions: undefined,
};

export default AccionaTableBody;
