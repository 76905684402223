import { Fragment, useContext, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { ConnectionContext } from '../context/ConnectionContext/provider';
import ErrorPage from '../pages/Utils/ErrorPage';
import TemplateFooter from '../components/TemplateFooter';
import TemplateHeader from '../components/TemplateHeader';
import { useSelector } from 'react-redux';
import UploadModal from '../components/AccionaModal/uploadModal';

const Layout = () => {
  const location = useLocation();
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const { loading, setLoading, error, uploadModal, setUploadModal } =
    useContext(ConnectionContext);

  useEffect(() => {
    setLoading(false);
  }, [location, setLoading]);

  return isLoggedIn ? (
    <>
      <TemplateHeader />
      <div className="content">
        {error ? (
          <ErrorPage errorMessages={error} />
        ) : (
          <Fragment>
            {loading && <Spinner loading />}
            <Outlet />
            <UploadModal
              show={uploadModal}
              onCancel={() => {
                setUploadModal(false);
              }}
            />
          </Fragment>
        )}
      </div>
      <TemplateFooter />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default Layout;
