import Step from '../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import AccionaButton from '../../../../../components/AccionaButton';
import AccionaFormItem from '../../../../../components/AccionaFormItem';
import AccionaInput from '../../../../../components/AccionaInput';
import { useEffect, useState } from 'react';
import SignaturePad from '../../../../../components/SignaturePad';
import { transformBase64ToFile } from '../../../../../services/api/utils';
import { useParams } from 'react-router-dom';

const { extraContent } = styles;

const PRMSignature = ({ onFinish, preregister, onCancel, onCreateNC }) => {
  const intl = useIntl();
  const { id: pid } = useParams();
  const [signer, setSigner] = useState(
    preregister.prm_data.signer || localStorage.getItem('username'),
  );
  const [signature, setSignature] = useState(
    preregister.prm_data.signature || [],
  );
  const [base64Signature, setBase64Signature] = useState(
    preregister.prm_data.base64Signature,
  );
  const [error, setError] = useState({ signer: false, signature: false });

  useEffect(() => {
    setError((prev) => ({ ...prev, signer: false }));
    if (signer && (signer.length < 3 || signer.length > 100)) {
      setError((prev) => ({ ...prev, signer: true }));
    }
  }, [signer]);

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.prm.step05' })}
      stepNumber={3}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      isTheLastStep
      extraContent={
        preregister.prm_data.defects_results === false ||
        preregister.prm_data.substance_results === false ? (
          <div className={extraContent}>
            <AccionaButton
              className="btn btn-red"
              onClick={() =>
                onCreateNC({
                  ...preregister,
                  prm_data: {
                    ...preregister.prm_data,
                    signer: signer,
                    signature: signature,
                    base64Signature,
                  },
                })
              }
              msg={intl.formatMessage({
                id: 'createNC',
              })}
              classIconRight="icon-right fas fa-chevron-right"
              disabled={error.signer || !signer || !signature.length}
            />
          </div>
        ) : null
      }
      content={
        <>
          <AccionaFormItem style={error ? { marginBottom: '5px' } : undefined}>
            <AccionaInput
              type="text"
              label="signer"
              onChange={(value) => {
                setSigner(value);
              }}
              state={{
                value: signer,
                status: error.signer ? 'error' : 'success',
              }}
            />
          </AccionaFormItem>
          <div className={`${error.detailedLocation ? 'infoError' : ''}`}>
            {error.detailedLocation &&
              intl.formatMessage(
                { id: 'validations.textLength' },
                { min: 3, max: 100 },
              )}
          </div>
          <SignaturePad
            signature={base64Signature}
            onChange={(base64Image) => {
              setSignature(
                base64Image
                  ? [
                      transformBase64ToFile(
                        base64Image,
                        `${preregister.docket_number}_${pid}_signature`,
                        '.png',
                      ),
                    ]
                  : null,
              );
              setBase64Signature(base64Image);
            }}
          />
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            signer: signer,
            signature: signature,
            base64Signature,
          },
        });
      }}
      disableNextButton={error.signer || !signer || !signature.length}
    />
  );
};

PRMSignature.propTypes = {
  preregister: PropTypes.shape({
    specimens_number: PropTypes.number,
    prm_data: PropTypes.shape({
      right_appearance: PropTypes.bool,
      right_appearance_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      right_appearance_comments: PropTypes.bool,
      limit_time: PropTypes.bool,
      limit_time_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      limit_time_comments: PropTypes.string,
      defects_results: PropTypes.bool,
      defects_results_comments: PropTypes.string,
      added_substance: PropTypes.bool,
      added_substance_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      added_substance_comments: PropTypes.string,
      substance_results: PropTypes.bool,
      substance_results_comments: PropTypes.string,
      specimens_comments: PropTypes.string,
      specimens_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      signer: PropTypes.string,
      signature: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      base64Signature: PropTypes.string,
    }),
    docket_number: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCreateNC: PropTypes.func.isRequired,
};

PRMSignature.defaultProps = {
  preregister: {},
};

export default PRMSignature;
