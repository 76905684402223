import { PropTypes } from 'prop-types';
import { Fragment, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccionaIcon from '../../components/AccionaIcon';
import AccionaLabel from '../../components/AccionaLabel';
import AccionaModal from '../../components/AccionaModal';
import ErrorModal from '../../components/AccionaModal/errorModal';
import AccionaTable from '../../components/AccionaTable';
import AccionaTableTabs from '../../components/AccionaTableTabs';
import RemoveModal from '../../components/RemoveModal';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import { getFile } from '../../services/api/files';
import { savePreDeliveryNoteOnline } from '../../services/api/preDeliveryNote';
import { transformBase64ToBlob } from '../../services/api/utils';
import * as OfflineActions from '../../services/redux/preDNOffline/actions';
import * as OnlineActions from '../../services/redux/preDNOnline/actions';
import { COLUMNS_HEADERS } from './columns';
import Details from './details';

const Tabs = {
  Online: 'Online',
  Offline: 'Offline',
};

const PreDNList = ({ getOnline, getOffline, deleteOnline, deleteOffline }) => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const [selectedTab, setSelectedTab] = useState(Tabs.Online);
  const { setLoading, online } = useContext(ConnectionContext);

  const [showAction, setShowAction] = useState(false);
  const onBack = () => window.history.back();
  const [removePDN, setRemovePDN] = useState(null);
  const [removePDNOffline, setRemovePDNOffline] = useState(null);
  const [selectedPDN, setSelectedPDN] = useState(null);
  const [previewIMG, setPreviwIMG] = useState(null);
  const [uploadOfflineError, setUploadOfflineError] = useState(null);
  const getIMGOnline = (img_name) => {
    setLoading(true);
    getFile('delivery', img_name).then((response) => {
      setPreviwIMG(URL.createObjectURL(response.data));
      setLoading(false);
    });
  };

  const getIMGOffline = (img) => {
    const blob = transformBase64ToBlob(img, 512, 'image/png');
    setPreviwIMG(URL.createObjectURL(blob));
  };

  useEffect(() => {
    if (!showAction) {
      document.querySelectorAll('.modalActions').forEach((element) => {
        element.style.setProperty('display', 'none');
      });
    }
  }, [showAction]);

  const [initialFilters, setInitialFilters] = useState({
    sortable: 'docket_number:ASC',
  });
  const uploadOffline = async (preDN) => {
    setLoading(true);
    try {
      await savePreDeliveryNoteOnline(preDN);
      deleteOffline(preDN.idCache);
    } catch (error) {
      setUploadOfflineError(error);
    }
    setLoading(false);
  };

  const showModalAction = (id) => {
    document.querySelectorAll('.modalActions').forEach((element) => {
      element.style.setProperty('display', 'none');
    });
    document
      .getElementById('modalActions' + id)
      .style.setProperty('display', 'flex');
  };

  const onTabChange = (tab) => {
    setSelectedTab(tab);
    setInitialFilters(undefined);
  };

  useEffect(() => {
    document.addEventListener('click', function (event) {
      const target = event.target;
      if (
        typeof target?.className !== 'string' ||
        (!target?.className.includes('fa-cog') &&
          !target?.className.includes('btn'))
      ) {
        document.querySelectorAll('.modalActions').forEach((element) => {
          element.style.setProperty('display', 'none');
        });
      }
    });
  }, []);

  return (
    <div className="wrapper" style={{ marginBottom: '60px' }}>
      <AccionaModal
        show={selectedPDN}
        onCancel={() => setSelectedPDN(null)}
        title={selectedPDN?.docket_number}
        content={<Details preRegisterDoc={selectedPDN} />}
      />
      <AccionaModal
        show={previewIMG}
        onCancel={() => setPreviwIMG(null)}
        content={
          <img
            id="region-select-canvas"
            alt="imgModal"
            src={previewIMG}
            width="100%"
          />
        }
      />

      <AccionaTableTabs
        selectedTab={selectedTab}
        onBack={onBack}
        setSelectedTab={onTabChange}
        title={formatMessage({ id: 'pdn.table.title' })}
        tabs={[Tabs.Online, Tabs.Offline]}
        render={() => {
          switch (selectedTab) {
            case Tabs.Online:
              return (
                <Fragment>
                  <RemoveModal
                    entity="preRegisteredDocuments"
                    msg="delete.preRegisterDocument"
                    onOk={() => {
                      deleteOnline(removePDN);
                      setRemovePDN(null);
                    }}
                    onCancel={() => setRemovePDN(null)}
                    hidden={!removePDN}
                  />

                  <AccionaTable
                    pid={id}
                    key={Tabs.Online}
                    initialFilters={{ ...initialFilters, used: false }}
                    getItems={getOnline}
                    entity="online"
                    columns={COLUMNS_HEADERS}
                    actions={(predn) => {
                      return (
                        <Fragment>
                          <AccionaIcon
                            id={`${predn.id}.viewSelect`}
                            className="btn btn-darkgray"
                            onClick={() => {
                              showModalAction(predn.id);
                              setShowAction(true);
                            }}
                            classIcon="icon fas fa-cog"
                          />
                          <div
                            className="modalActions"
                            id={`modalActions${predn.id}`}
                          >
                            <AccionaIcon
                              id={`${predn.id}.viewSelect`}
                              className="btn btn-darkgray"
                              onClick={() => {
                                getIMGOnline(predn.image_name);
                                setShowAction(false);
                              }}
                              classIcon="icon fas fa-eye"
                            />
                            <AccionaIcon
                              id={`${predn.id}.preRegisterSelect`}
                              onClick={() => {
                                setSelectedPDN(predn);
                                setShowAction(false);
                              }}
                              className="btn btn-darkgray"
                              classIcon="icon fas fa-file"
                            />{' '}
                            {predn.prm_data ? (
                              <AccionaLabel>
                                {formatMessage({ id: 'prm' })}
                              </AccionaLabel>
                            ) : (
                              <Fragment>
                                {/* <AccionaIcon
                                id={`${predn.id}.editSelect`}
                                onClick={() => {
                                  // editAction(x);
                                  setShowAction(false);
                                }}
                                className="btn btn-darkgray"
                                classIcon="icon fas fa-pencil-alt"
                              /> */}
                                <AccionaIcon
                                  id={`${predn.id}.deleteSelect`}
                                  onClick={() => {
                                    setRemovePDN(predn.id);
                                    setShowAction(false);
                                  }}
                                  className="btn btn-darkgray"
                                  classIcon="icon fas fa-trash-alt"
                                />{' '}
                              </Fragment>
                            )}
                          </div>
                        </Fragment>
                      );
                    }}
                  />
                </Fragment>
              );
            case Tabs.Offline:
              return (
                <Fragment>
                  <RemoveModal
                    entity="preRegisteredDocuments"
                    msg="delete.preRegisterDocument"
                    onOk={() => {
                      deleteOffline(removePDNOffline);
                      setRemovePDNOffline(null);
                    }}
                    onCancel={() => setRemovePDNOffline(null)}
                    hidden={!removePDNOffline}
                  />
                  <ErrorModal
                    onCancel={() => setUploadOfflineError(null)}
                    show={uploadOfflineError}
                    error={uploadOfflineError}
                  />

                  <AccionaTable
                    pid={id}
                    key={Tabs.Offline}
                    initialFilters={initialFilters}
                    getItems={getOffline}
                    entity="offline"
                    columns={COLUMNS_HEADERS}
                    actions={(predn) => {
                      return (
                        <Fragment>
                          <AccionaIcon
                            id={`${predn.id}.viewSelect`}
                            className="btn btn-darkgray"
                            onClick={() => {
                              showModalAction(predn.idCache);
                              setShowAction(true);
                            }}
                            classIcon="icon fas fa-cog"
                          />
                          <div
                            className="modalActions"
                            id={`modalActions${predn.idCache}`}
                          >
                            <AccionaIcon
                              id={`${predn.idCache}.viewSelect`}
                              className="btn btn-darkgray"
                              disabled={!online.state}
                              onClick={() => {
                                uploadOffline(predn);
                                setShowAction(false);
                              }}
                              classIcon="icon fas fa-upload"
                            />
                            <AccionaIcon
                              id={`${predn.idCache}.viewSelect`}
                              className="btn btn-darkgray"
                              onClick={() => {
                                getIMGOffline(predn.photo_element);
                                setShowAction(false);
                              }}
                              classIcon="icon fas fa-eye"
                            />
                            <AccionaIcon
                              id={`${predn.idCache}.preRegisterSelect`}
                              onClick={() => {
                                setSelectedPDN(predn);
                                setShowAction(false);
                              }}
                              className="btn btn-darkgray"
                              classIcon="icon fas fa-file"
                            />
                            <Fragment>
                              <AccionaIcon
                                id={`${predn.idCache}.deleteSelect`}
                                onClick={() => {
                                  setRemovePDNOffline(predn.idCache);
                                  setShowAction(false);
                                }}
                                className="btn btn-darkgray"
                                classIcon="icon fas fa-trash-alt"
                              />{' '}
                            </Fragment>
                          </div>
                        </Fragment>
                      );
                    }}
                  />
                </Fragment>
              );
            default:
              return null;
          }
        }}
      ></AccionaTableTabs>
    </div>
  );
};
const mapStateToProps = () => ({});

const matchDispachToProps = {
  getOnline: OnlineActions.getPreDNOnline,
  getOffline: OfflineActions.getPreDNOffline,
  deleteOnline: OnlineActions.deletePreDNOnline,
  deleteOffline: OfflineActions.deletePreDNOffline,
};

PreDNList.propTypes = {
  getOnline: PropTypes.func.isRequired,
  getOffline: PropTypes.func.isRequired,
  deleteOnline: PropTypes.func.isRequired,
  deleteOffline: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, matchDispachToProps)(PreDNList);
