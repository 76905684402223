import { PropTypes } from 'prop-types';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import AccionaPhoto from '../../../../../components/AccionaPhoto';
import Step from '../../../../../components/Step';
import styles from './index.module.scss';

const { photoPreview } = styles;

const PhotoUploading = ({ onFinish, preregister, onCancel, stepNumber }) => {
  const intl = useIntl();
  const inputFileRef = useRef(null);
  const [photo_element, setPhotoElement] = useState(preregister.photo_element);
  const [error, setError] = useState({
    photoUploading: false,
    imageName: false,
  });

  const onFilechange = (files) => {
    setError((prev) => ({ ...prev, photoUploading: false }));
    setPhotoElement(files[0]);
  };

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step08',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <input
            id="imgInput"
            type="file"
            capture
            accept="image/jpg, image/jpeg, image/png"
            style={{ display: 'none' }}
            ref={inputFileRef}
            onChangeCapture={onFilechange}
          />
          {photo_element && (
            <div className={photoPreview}>
              <img
                id="imgPreregister"
                src={URL.createObjectURL(photo_element)}
                style={{ display: !photo_element ? 'none' : '' }}
                alt="photoDelivery"
              />
            </div>
          )}
          <AccionaPhoto
            onChange={onFilechange}
            // onChange={() => {}}
            label={!photo_element ? 'deliveryPhoto' : 'repeatDeliveryPhoto'}
          />
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          photo_element,
        });
      }}
      disableNextButton={error.photo_element || !photo_element}
    />
  );
};

PhotoUploading.propTypes = {
  preregister: PropTypes.shape({
    docket_number: PropTypes.string,
    photo_element: PropTypes.object,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

PhotoUploading.defaultProps = {
  preregister: {},
};

export default PhotoUploading;
