import React from 'react';
import { PropTypes } from 'prop-types';

const AccionaButton = ({
  onClick,
  msg,
  classIcon,
  className,
  htmlType,
  disabled,
  classIconRight,
  style,
}) => (
  <button
    style={style}
    className={`${className} ${disabled ? 'disabled' : ''}`}
    onClick={onClick}
    type={htmlType}
    disabled={disabled}
    id="AccionaButton"
  >
    {classIcon && <i className={classIcon} />}
    {msg}
    {classIconRight && <i className={classIconRight} />}
  </button>
);

AccionaButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  msg: PropTypes.string,
  classIcon: PropTypes.string,
  classIconRight: PropTypes.string,
  className: PropTypes.string,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

AccionaButton.defaultProps = {
  onClick: () => {},
  msg: undefined,
  classIcon: undefined,
  classIconRight: undefined,
  className: 'btn btn-red',
  htmlType: 'button',
  disabled: false,
  style: {},
};

export default AccionaButton;
