import { useIntl } from 'react-intl';
import Step from '../../../../../components/Step';
import { PropTypes } from 'prop-types';
import Acciona2CheckButton from '../../../../../components/Acciona2CheckButton';
import PhotoList from '../../../../../components/AccionaPhotoList';
import { useState } from 'react';
import AccionaPhoto from '../../../../../components/AccionaPhoto';
import AccionaInput from '../../../../../components/AccionaInput';
import AccionaFormItem from '../../../../../components/AccionaFormItem';

const PRMRightAppearance = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();
  const [rightAppearance, setRightAppearance] = useState(
    preregister.prm_data?.right_appearance || false,
  );
  const [rightAppearanceImage, setRightAppearanceImage] = useState(
    preregister.prm_data?.right_appearance_img || [],
  );
  const [rightAppearanceComments, setRightAppearanceComments] = useState(
    preregister.prm_data?.right_appearance_comments,
  );

  const updatePhotoValues = (files) => {
    setRightAppearanceImage((prev) => [...prev, ...files]);
  };

  const deleteImage = (index) => {
    setRightAppearanceImage((prev) => {
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  return (
    <Step
      stepNumber={1}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.prm.step01',
      })}
      content={
        <>
          <AccionaFormItem>
            <Acciona2CheckButton
              label="right_appearance"
              state={{
                value: rightAppearance,
              }}
              onChange={(value) => setRightAppearance(value)}
            />
          </AccionaFormItem>
          <PhotoList images={rightAppearanceImage} onDelete={deleteImage} />
          <AccionaPhoto onChange={updatePhotoValues} label="concretePhoto" />
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="comment"
              onChange={(value) => setRightAppearanceComments(value)}
              state={{
                value: rightAppearanceComments,
              }}
            />
          </AccionaFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            right_appearance: rightAppearance,
            right_appearance_img: rightAppearanceImage,
            right_appearance_comments: rightAppearanceComments,
          },
        });
      }}
    />
  );
};

PRMRightAppearance.propTypes = {
  preregister: PropTypes.shape({
    prm_data: PropTypes.shape({
      right_appearance: PropTypes.bool,
      right_appearance_img: PropTypes.number,
      right_appearance_comments: PropTypes.number,
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PRMRightAppearance.defaultProps = {
  preregister: {},
};

export default PRMRightAppearance;
