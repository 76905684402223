import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import AccionaButton from '../AccionaButton';
import styles from './index.module.scss';
import { useContext, useEffect } from 'react';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import AccionaIcon from '../AccionaIcon';
import { connect } from 'react-redux';

const filterStyle = {
  padding: '10px',
  backgroundColor: '#e6e6e6',
  textTransform: 'uppercase',
  height: '56px',
  margin: '0px',
};

const AccionaTableTabs = ({
  selectedTab,
  setSelectedTab,
  onBack,
  render,
  tabs,
  header,
  title,
  titleClassName,
  tabsClassName,
  totalOfflineDocs,
}) => {
  const intl = useIntl();
  const { online, setUploadModal } = useContext(ConnectionContext);

  useEffect(() => {
    if (online.state === false) setSelectedTab(tabs[1]);
  }, [online.state, tabs, setSelectedTab]);

  return (
    <>
      <div
        className="wrapper"
        style={{ maxWidth: '1200px', padding: '20px 0px' }}
      >
        <div className="form-row align-right-sd no-table">{header}</div>
        <div className="card no-padding no-margin" id="AccionaTable">
          <div className="dataTables_wrapper no-footer">
            <div style={{ ...filterStyle, padding: '10px 20px' }}>
              <div className="row">
                <div
                  className={`col-xs-9 ${titleClassName ?? ''}`}
                  style={{ paddingTop: '3px' }}
                >
                  {title}
                </div>
                {selectedTab === tabs[1] && (
                  <div className="col-xs-3">
                    <AccionaIcon
                      disabled={
                        totalOfflineDocs === 0 || online.state === false
                      }
                      id="header.viewSelect"
                      className="btn btn-red"
                      style={{ float: 'right' }}
                      onClick={() => {
                        setUploadModal(true);
                      }}
                      classIcon="icon fas fa-upload"
                    />
                  </div>
                )}
              </div>
            </div>
            <div style={filterStyle}>
              <div
                className="form-row align-left-sd no-table"
                style={{
                  margin: '0px',
                }}
              >
                {tabs.map((tab) => (
                  <AccionaButton
                    key={tab}
                    onClick={() => setSelectedTab(tab)}
                    disabled={!online.state}
                    msg={intl.formatMessage({
                      id: `button${tab[0].toUpperCase()}${tab.substring(1)}`,
                    })}
                    className={`${styles.tab} ${
                      selectedTab === tab ? '' : styles.noSelected
                    } ${tabsClassName ?? ''}`}
                  />
                ))}
              </div>
            </div>
            {render()}
          </div>
        </div>
        <div className="form-row">
          <AccionaButton
            msg={intl.formatMessage({ id: 'back' })}
            onClick={onBack}
            classIcon="icon fas fa-chevron-left"
            style={{ float: 'right', marginTop: '10px', marginRight: '0px' }}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = ({ offline }) => ({
  totalOfflineDocs: offline.total,
});

AccionaTableTabs.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  totalOfflineDocs: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  header: PropTypes.element,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  tabsClassName: PropTypes.string,
};

AccionaTableTabs.defaultProps = {
  title: 'Table',
  titleClassName: undefined,
  tabsClassName: undefined,
  totalOfflineDocs: 0,
  header: undefined,
};

export default connect(mapStateToProps, {})(AccionaTableTabs);
