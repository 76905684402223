import {
  CaretDownOutlined,
  CaretUpOutlined,
  FilterOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { PropTypes } from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AccionaButton from '../../components/AccionaButton';

const AccionaTableHeader = ({
  columns,
  selectedPage,
  entity,
  setFilters,
  setPagination,
  docketFilter = null,
  total,
  filters,
}) => {
  const intl = useIntl();
  useEffect(() => {
    const allFilterPages = Math.ceil(total / 5);
    setPagination((prev) => ({
      ...prev,
      current: allFilterPages >= selectedPage ? selectedPage : 1,
    }));
  }, [selectedPage, setFilters, total, setPagination]);

  const removeSort = (iconAsc, iconDesc) => {
    iconAsc.classList.remove('iconSortAscSelected');
    iconDesc.classList.remove('iconSortDescSelected');
    setFilters((prevState) => {
      delete prevState['sortable'];
      return { ...prevState };
    });
  };

  useEffect(() => {
    if (docketFilter) {
      document.getElementById('searchableInput-docket_number').value =
        docketFilter || '';
    }
  }, [docketFilter]);

  const sort = (column, order) => {
    const iconAsc = document.getElementById(`sortAsc-${column}`);
    const iconDesc = document.getElementById(`sortDesc-${column}`);
    let newFilter = {};

    Array.from(document.getElementsByClassName('iconSortAsc')).forEach(
      (element) => {
        if (!element.getAttribute('id').includes(column)) {
          element.classList.remove('iconSortAscSelected');
        }
      },
    );
    Array.from(document.getElementsByClassName('iconSortDesc')).forEach(
      (element) => {
        if (!element.getAttribute('id').includes(column)) {
          element.classList.remove('iconSortDescSelected');
        }
      },
    );
    switch (order) {
      case 'ASC':
        if (!iconAsc.className.includes('iconSortAscSelected')) {
          iconAsc.classList.add('iconSortAscSelected');
          iconDesc.classList.remove('iconSortDescSelected');
          newFilter['sortable'] = column + ':ASC';
          setFilters((prevState) => ({ ...prevState, ...newFilter }));
        } else {
          removeSort(iconAsc, iconDesc, column, newFilter);
        }
        break;
      case 'DESC':
        if (!iconDesc.className.includes('iconSortDescSelected')) {
          iconDesc.classList.add('iconSortDescSelected');
          iconAsc.classList.remove('iconSortAscSelected');
          newFilter['sortable'] = column + ':DESC';
          setFilters((prevState) => ({ ...prevState, ...newFilter }));
        } else {
          removeSort(iconAsc, iconDesc, column, newFilter);
        }
        break;
      default:
        break;
    }
  };

  const hiddenModals = (column) => {
    Array.from(document.getElementsByClassName(`modalFilterable`)).forEach(
      (element) => {
        if (!element.getAttribute('id').includes(column))
          element.classList.remove('modalFilterable');
      },
    );
    Array.from(document.getElementsByClassName(`modalSearchable`)).forEach(
      (element) => {
        if (!element.getAttribute('id').includes(column))
          element.classList.remove('modalSearchable');
      },
    );
  };

  const filter = (column) => {
    hiddenModals(column);
    const filterModal = document.getElementById(`filterable-${column}`);
    filterModal.classList.toggle('modalFilterable');
  };

  const handleFilter = (column, data = undefined) => {
    let newFilter = {};
    if (data !== undefined) {
      newFilter[column] = data;
    } else {
      delete newFilter[column];
      document
        .querySelectorAll(`#filterable-${column} input[type="radio"]`)
        .forEach((element) => (element.checked = false));
    }
    setFilters((prevState) => ({ ...prevState, ...newFilter }));
    const filterModal = document.getElementById(`filterable-${column}`);
    filterModal.classList.toggle('modalFilterable');
  };

  const search = (column) => {
    hiddenModals(column);
    const searchModal = document.getElementById(`searchable-${column}`);
    searchModal.classList.toggle('modalSearchable');
  };

  const hadleSearch = (column) => {
    let newFilter = {};
    const stringToSearch = document.getElementById(
      `searchableInput-${column}`,
    ).value;
    newFilter[column] = columns[column].prefix
      ? `${columns[column].prefix}${stringToSearch}`
      : stringToSearch;
    if (newFilter[column] === '') {
      delete newFilter[column];
      setFilters((prevState) => {
        delete prevState[column];
        return prevState;
      });
    }
    setFilters((prevState) => ({ ...prevState, ...newFilter }));
    const searchModal = document.getElementById(`searchable-${column}`);
    searchModal.classList.toggle('modalSearchable');
  };

  return (
    <thead id="AccionaTableHeader">
      <tr id="HeaderRow">
        {Object.keys(columns).map((x) => {
          if (columns[x].isVisible === false || x === 'collapsableColums')
            return null;
          var headerValue =
            entity === 'preProcessedDocuments' && x === 'supplier'
              ? 'associatedTemplate'
              : x;
          return (
            <th
              key={`${x}Header`}
              id={`${x}Header`}
              style={{ position: 'relative' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <FormattedMessage id={`${headerValue}`} />
                {columns[x].sortable ? (
                  <div
                    style={{
                      margin: '-2px 5px 0 5px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <CaretUpOutlined
                      id={`sortAsc-${x}`}
                      className={`iconSortAsc ${
                        columns[x].ascSort ? 'iconSortAscSelected' : ''
                      }`}
                      onClick={() => sort(x, 'ASC')}
                    />
                    <CaretDownOutlined
                      id={`sortDesc-${x}`}
                      className="iconSortDesc"
                      onClick={() => sort(x, 'DESC')}
                    />
                  </div>
                ) : null}
                {columns[x].filterable ? (
                  <>
                    <div
                      style={{ margin: '1px 0 0 5px', cursor: 'pointer' }}
                      onClick={() => filter(x)}
                    >
                      <FilterOutlined />
                    </div>
                  </>
                ) : null}
                {columns[x].searchable ? (
                  <>
                    <div
                      style={{ margin: '1px 0 0 5px', cursor: 'pointer' }}
                      onClick={() => search(x)}
                    >
                      <SearchOutlined />
                    </div>
                  </>
                ) : null}
              </div>
              {columns[x].filterable ? (
                <div id={`filterable-${x}`} className="modalFilterableHidden">
                  {Object.values(columns[x].filterableData).map((data) => {
                    return (
                      <div
                        key={`filterable-${data}`}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: '6px 8px 0px 8px',
                        }}
                      >
                        <label htmlFor={`filterableInput-${data}`}>
                          <input
                            type="radio"
                            name="filter"
                            onChange={() => handleFilter(x, data)}
                            id={`filterableInput-${data}`}
                            style={{ margin: '0px' }}
                          />
                          <span
                            style={{ marginLeft: '10px' }}
                            id={`filterableData-${data}`}
                          >
                            {intl.formatMessage({ id: data })}
                          </span>
                        </label>
                      </div>
                    );
                  })}
                  <AccionaButton
                    onClick={() => handleFilter(x)}
                    msg={intl.formatMessage({ id: 'uncheck' })}
                    classIcon="icon fas fa-window-close"
                    style={{
                      margin: '8px auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      width: '85%',
                    }}
                  />
                </div>
              ) : null}
              {columns[x].searchable ? (
                <div id={`searchable-${x}`} className="modalSearchableHidden">
                  <input
                    type="text"
                    id={`searchableInput-${x}`}
                    style={{
                      backgroundColor: '#ebebeb',
                      width: '85%',
                      border: '1px solid #bfbfbf',
                      marginTop: '10px',
                    }}
                    defaultValue={filters?.[x]}
                  />
                  <AccionaButton
                    onClick={() => hadleSearch(x)}
                    msg={intl.formatMessage({ id: 'search' })}
                    classIcon="icon fas fa-search"
                    style={{
                      margin: '8px auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      width: '65%',
                    }}
                  />
                </div>
              ) : null}
            </th>
          );
        })}
        <th id="ButtonHeader">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <FormattedMessage id="actions" />
          </div>
        </th>
      </tr>
    </thead>
  );
};

AccionaTableHeader.propTypes = {
  columnsName: PropTypes.array,
  columns: PropTypes.object.isRequired,
  selectedPage: PropTypes.number.isRequired,
  entity: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  docketFilter: PropTypes.number,
  total: PropTypes.number.isRequired,
  filters: PropTypes.object,
};

AccionaTableHeader.defaultProps = {
  columnsName: [],
  filters: undefined,
  docketFilter: undefined,
};

export default AccionaTableHeader;
