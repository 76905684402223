import { useEffect, useState } from 'react';
import AccionaInput from '../../../../../components/AccionaInput';
import Step from '../../../../../components/Step';
import AccionaFormItem from '../../../../../components/AccionaFormItem';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';

const DetailedLocation = ({ onFinish, preregister, onCancel, stepNumber }) => {
  const intl = useIntl();
  const [detailedLocation, setDetailedLocation] = useState(
    preregister.detailed_location,
  );
  const [error, setError] = useState({ detailedLocation: false });

  useEffect(() => {
    setError((prev) => ({ ...prev, detailedLocation: false }));
    if (
      detailedLocation &&
      (detailedLocation.length < 3 || detailedLocation.length > 100)
    ) {
      setError((prev) => ({ ...prev, detailedLocation: true }));
    }
  }, [detailedLocation]);

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step07',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <AccionaFormItem style={error ? { marginBottom: '5px' } : undefined}>
            <AccionaInput
              type="text"
              label="detailed_location"
              onChange={(value) => {
                setDetailedLocation(value);
              }}
              state={{
                value: detailedLocation,
                status: error.detailedLocation ? 'error' : 'success',
              }}
            />
          </AccionaFormItem>
          <div className={`${error.detailedLocation ? 'infoError' : ''}`}>
            {error.detailedLocation &&
              intl.formatMessage(
                { id: 'validations.textLength' },
                { min: 3, max: 100 },
              )}
          </div>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({ ...preregister, detailed_location: detailedLocation });
      }}
      disableNextButton={error.detailedLocation || !detailedLocation}
    />
  );
};

DetailedLocation.propTypes = {
  preregister: PropTypes.shape({
    detailed_location: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

DetailedLocation.defaultProps = {
  preregister: {},
};

export default DetailedLocation;
