import { useEffect, useState } from 'react';
import Step from '../../../../../components/Step';
import AccionaFormItem from '../../../../../components/AccionaFormItem';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import AccionaTimePicker from '../../../../../components/AccionaTimePicker';
import moment from 'moment';
import { validateStartDischargingTime } from '../../../index.utils';

const StartDischargingTime = ({
  onFinish,
  preregister,
  onCancel,
  stepNumber,
}) => {
  const intl = useIntl();
  const [startDischargingTime, setStartDischargingTime] = useState(
    preregister.start_discharging_time || moment().format('HH:mm'),
  );
  const [error, setError] = useState({
    startDischargingTime: false,
  });

  useEffect(() => {
    if (startDischargingTime) {
      setError((prev) => ({ ...prev, startDischargingTime: false }));
      const isAValidTime = validateStartDischargingTime({
        startDischargingTime,
        arrivingTime: preregister.arriving_time,
      });
      if (!isAValidTime) {
        setError((prev) => ({
          ...prev,
          startDischargingTime: true,
        }));
      }
    }
  }, [preregister.arriving_time, startDischargingTime]);

  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step03',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <AccionaFormItem style={error ? { marginBottom: '5px' } : undefined}>
            <AccionaTimePicker
              label="start_discharging_time"
              onUpdate={() => setStartDischargingTime(moment().format('HH:mm'))}
              onChange={(inputValue) => setStartDischargingTime(inputValue)}
              state={{
                value: startDischargingTime ?? moment().format('HH:mm'),
                status: error.startDischargingTime ? 'error' : 'success',
              }}
            />
          </AccionaFormItem>
          <div className={`${error.startDischargingTime ? 'infoError' : ''}`}>
            {error.startDischargingTime &&
              intl.formatMessage(
                {
                  id: 'validations.startDischargingTimeMustBeAfterThanArrivingTime',
                },
                { time: moment().format('HH:mm') },
              )}
          </div>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          start_discharging_time: startDischargingTime,
        });
      }}
      disableNextButton={error.startDischargingTime || !startDischargingTime}
    />
  );
};

StartDischargingTime.propTypes = {
  preregister: PropTypes.shape({
    start_discharging_time: PropTypes.string,
    arriving_time: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

StartDischargingTime.defaultProps = {
  preregister: {},
};

export default StartDischargingTime;
