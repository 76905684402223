import { combineReducers } from 'redux';
import auth from './auth/reducer';
import online from './preDNOnline/reducer';
import offline from './preDNOffline/reducer';
import projects from './projects/reducer';
import uploadModal from './uploadModal/reducer';

export default combineReducers({
  projects,
  online,
  auth,
  offline,
  uploadModal,
});
