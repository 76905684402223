import { useIntl } from 'react-intl';
import Step from '../../../../../components/Step';
import { PropTypes } from 'prop-types';
import Acciona3CheckButton from '../../../../../components/Acciona3CheckButton';
import { useState } from 'react';
import AccionaInput from '../../../../../components/AccionaInput';
import AccionaFormItem from '../../../../../components/AccionaFormItem';

const PRMSubstanceResults = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();
  const [substanceResults, setSubstanceResults] = useState(
    preregister.prm_data?.substance_results || false,
  );
  const [substanceResultsComments, setSubstanceResultsComments] = useState(
    preregister.prm_data?.substance_results_comments || '',
  );

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.prm.step02' })}
      stepNumber={2}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      content={
        <>
          <AccionaFormItem>
            <Acciona3CheckButton
              label="substance_results"
              state={{
                value: substanceResults,
              }}
              onChange={(value) => setSubstanceResults(value)}
            />
          </AccionaFormItem>
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="comment"
              onChange={(value) => setSubstanceResultsComments(value)}
              state={{
                value: substanceResultsComments,
              }}
            />
          </AccionaFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({
          ...preregister,
          prm_data: {
            ...preregister.prm_data,
            substance_results: substanceResults,
            substance_results_comments: substanceResultsComments,
          },
        });
      }}
    />
  );
};

PRMSubstanceResults.propTypes = {
  preregister: PropTypes.shape({
    prm_data: PropTypes.shape({
      substance_results: PropTypes.bool,
      substance_results_img: PropTypes.number,
      substance_results_comments: PropTypes.number,
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PRMSubstanceResults.defaultProps = {
  preregister: {},
};

export default PRMSubstanceResults;
