import Step from '../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import PreregisterResume from '../../../common/PreregisterResume';
import Resume from '../../../common/PRMResume';

const { title } = styles;

const PRMResume = ({ onFinish, preregister, onCancel }) => {
  const intl = useIntl();

  return (
    <Step
      title={intl.formatMessage({ id: 'preregisterWizard.section.prm.step04' })}
      stepNumber={3}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.prm',
      })}
      content={
        <>
          <h4 className={title}>
            {intl.formatMessage({ id: `finishPreRegisterContent` })}
          </h4>
          <PreregisterResume preregister={preregister} />
          <Resume preregister={preregister} />
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish();
      }}
    />
  );
};

PRMResume.propTypes = {
  preregister: PropTypes.shape({
    specimens_number: PropTypes.number,
    prm_data: PropTypes.shape({
      right_appearance: PropTypes.bool,
      right_appearance_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      right_appearance_comments: PropTypes.bool,
      limit_time: PropTypes.bool,
      limit_time_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      limit_time_comments: PropTypes.string,
      defects_results: PropTypes.bool,
      defects_results_comments: PropTypes.string,
      added_substance: PropTypes.bool,
      added_substance_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
      added_substance_comments: PropTypes.string,
      substance_results: PropTypes.bool,
      substance_results_comments: PropTypes.string,
      specimens_comments: PropTypes.string,
      specimens_img: PropTypes.arrayOf(PropTypes.instanceOf(File)),
    }),
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

PRMResume.defaultProps = {
  preregister: {},
};

export default PRMResume;
