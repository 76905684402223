import moment from 'moment';
import 'moment/locale/es';
import { PropTypes } from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import * as ProjectsActions from '../../services/redux/projects/actions';
import ProjectsList from './ProjectsList';

moment.locale(navigator.language.substring(0, 2));

const Main = ({
  getProjectsList,
  projectsList,
  errorMessages,
  createErrors,
  clearErrors,
}) => {
  const navigate = useNavigate();
  const [selectedProject, setSelectedProject] = useState(null);
  const { setLoading } = useContext(ConnectionContext);

  useEffect(() => {
    setLoading(true);
    try {
      getProjectsList({ size: 100, page: 1 });
      clearErrors(null);
      setLoading(false);
    } catch (error) {
      createErrors({
        status: error.status,
        statusText: error.statusText,
        errorType: 'GET_PROJECTS_ERROR',
      });
    }
  }, [clearErrors, createErrors, getProjectsList, setLoading]);

  useEffect(() => {
    if (selectedProject !== null)
      navigate(`/app/project/${selectedProject.id_project}`);
  }, [selectedProject, navigate]);

  useEffect(() => {
    if (errorMessages) {
      createErrors({
        status: errorMessages.status,
        statusText: errorMessages.statusText,
        errorType: 'GET_PROJECTS_ERROR',
      });
    }
  }, [createErrors, errorMessages]);

  return (
    <ProjectsList
      allProjects={projectsList}
      onClick={(project) => setSelectedProject(project)}
    />
  );
};

const mapStateToProps = ({ projects }) => ({
  projectsList: projects.list,
  total: projects.total,
  fetching: projects.fetching,
  errorMessages: projects.errorMessages,
});

const matchDispachToProps = {
  getProjectsList: ProjectsActions.getProjects,
  clearState: ProjectsActions.clearProjectsState,
  clearErrors: ProjectsActions.clearErrors,
  createErrors: ProjectsActions.createErrors,
};

Main.defaultProps = {
  errorMessages: {},
  projectsList: [],
};

Main.propTypes = {
  getProjectsList: PropTypes.func.isRequired,
  errorMessages: PropTypes.shape({
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
  createErrors: PropTypes.func.isRequired,
  projectsList: PropTypes.arrayOf(PropTypes.object),
  clearErrors: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, matchDispachToProps)(Main);
