import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { countPreDNCache, getAllPreDNPid } from '../../../indexedDB/utils';
import { PreRegisterDocumentCache } from '../../../models/PreRegisterDocument';
import * as types from './types';
import * as offlineTypes from '../preDNOffline/types';
import { savePreDeliveryNoteOnline } from '../../api/preDeliveryNote';

function* countPreDNOffline({ payload }) {
  const response = yield call(countPreDNCache, payload);
  yield put({
    type: types.COUNT_PRE_DN_OFFLINE_SUCCESS,
    payload: response,
  });
}

function* uploadAll({ payload }) {
  const response = yield call(getAllPreDNPid, payload);
  yield put({
    type: types.COUNT_PRE_DN_OFFLINE_SUCCESS,
    payload: response.length,
  });
  for (const i in response) {
    const preDNCache = PreRegisterDocumentCache.fromCache(response[i]);
    try {
      const uploadResponse = yield call(savePreDeliveryNoteOnline, preDNCache);
      if (uploadResponse.status === 201) {
        yield put({
          type: offlineTypes.DELETE_PRE_DN_OFFLINE_REQUEST,
          payload: preDNCache.id,
        });
        yield put({
          type: types.UPLOAD_ONE_SUCCESS,
          payload: {
            id: preDNCache.id,
          },
        });
      } else {
        yield put({
          type: types.UPLOAD_ONE_ERROR,
          payload: response,
        });
      }
    } catch (error) {
      yield put({
        type: types.UPLOAD_ONE_ERROR,
        payload: error,
      });
    }
  }
  yield put({
    type: types.UPLOAD_ALL_SUCCESS,
  });
}

function* watcherUploadPreRegistersDocuments() {
  yield takeEvery(types.UPLOAD_ALL_REQUEST, uploadAll);
}
function* watcherCountPreRegisterDocuments() {
  yield takeEvery(types.COUNT_PRE_DN_OFFLINE_REQUEST, countPreDNOffline);
}

export default function* rootSaga() {
  yield all([
    fork(watcherCountPreRegisterDocuments),
    fork(watcherUploadPreRegistersDocuments),
  ]);
}
