import { all } from 'redux-saga/effects';
import ProjectsSaga from './projects/saga';
import AuthSaga from './auth/saga';
import UploadModalSaga from './uploadModal/saga';
import OnlineSaga from './preDNOnline/saga';
import OfflineSaga from './preDNOffline/saga';

export default function* rootSaga() {
  yield all([
    ProjectsSaga(),
    AuthSaga(),
    OnlineSaga(),
    OfflineSaga(),
    UploadModalSaga(),
  ]);
}
