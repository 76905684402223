import * as TYPES from './types';

const initialState = {
  fetching: false,
  total: 0,
  error: 0,
  uploaded: 0,
};
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TYPES.COUNT_PRE_DN_OFFLINE_REQUEST:
      return { ...state };

    case TYPES.UPLOAD_ALL_REQUEST:
      return { ...state, uploaded: 0, error: 0, fetching: true };

    case TYPES.UPLOAD_ONE_ERROR: {
      return { ...state, error: state.error + 1 };
    }
    case TYPES.UPLOAD_ONE_SUCCESS: {
      return {
        ...state,
        uploaded: state.uploaded + 1,
      };
    }
    case TYPES.UPLOAD_ALL_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case TYPES.COUNT_PRE_DN_OFFLINE_SUCCESS:
      return { ...state, total: payload, uploaded: 0, error: 0 };

    default:
      return { ...state };
  }
};

export default reducer;
