import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ConnectionContext } from '../../context/ConnectionContext/provider';
import { getLogout } from '../../services/redux/auth/actions';
import profile from '../../style/img/profile_placeholder.svg';
import logoAcciona from '../../style/imgPWA/logoAcciona.png';
import styles from './Header.module.css';

const { header, adminButton } = styles;

const Header = () => {
  const userName = localStorage.getItem('username');
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showProfileMenu, setShowProfileMenu] = useState(false);

  const { online, setLoading } = useContext(ConnectionContext);

  useEffect(() => {
    const listener = () => online.setOnline(false);
    window.addEventListener('offline', listener);

    return () => {
      window.removeEventListener('offline', listener);
    };
  }, [online]);

  useEffect(() => {
    const listener = () => {
      online.setOnline(true);

      const controlRequest = localStorage.getItem('controlRequest');

      if (controlRequest !== null && controlRequest === 'false') {
        localStorage.setItem('controlRequest', true);
      }
    };
    window.addEventListener('online', listener);
  }, [online, setLoading]);

  useEffect(() => {
    const listener = ({ target }) => {
      if (
        !target.classList.contains('profile-menu') &&
        !target.parentNode?.classList.contains('profile-button')
      ) {
        setShowProfileMenu(false);
      }
    };
    if (showProfileMenu) {
      document.addEventListener('click', listener);
    }
    return () => {
      if (listener) {
        document.removeEventListener('click', listener);
      }
    };
  }, [showProfileMenu]);

  const ProjectsButton = () => (
    <button
      className="projectsButton"
      onClick={() => {
        navigate('/app/projects');
      }}
    >
      <span>{formatMessage({ id: `projects` })}</span>
    </button>
  );

  const UserButton = () => (
    <button
      className={`profile-button ${adminButton}`}
      onClick={() => setShowProfileMenu(true)}
    >
      <img src={profile} alt="profile" />
      <span style={{ marginLeft: '6px', fontSize: '15px' }}>{userName}</span>
    </button>
  );

  const OfflineAlert = () => (
    <div
      style={{
        backgroundColor: 'red',
        padding: '3px 12px',
        margin: '12px',
        borderRadius: '15px',
        color: 'white',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {formatMessage({ id: `offlineMode` })}
    </div>
  );

  return (
    <header className={header}>
      <div>
        <a href="/">
          <img id="logo" src={logoAcciona} alt="logo"></img>
        </a>
      </div>
      <div>{online.state ? null : <OfflineAlert />}</div>
      <div>
        <ProjectsButton />
        <UserButton />
        <div className={`profile-menu ${showProfileMenu ? '' : 'hidden'}`}>
          <ul>
            <li>
              <button onClick={() => dispatch(getLogout())}>
                <i className="icon fa fa-sign-out-alt"></i>
                {formatMessage({ id: `closeSession` })}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
