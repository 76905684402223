import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from './modalV2';

const ErrorModal = ({ show, onCancel, error }) => {
  const { formatMessage } = useIntl();
  const [messageId, setMessageId] = useState('errorUnknown');

  useEffect(() => {
    if (show) {
      switch (error.response.data.message) {
        case 'Pre delivery note with that docker_number and project already exists':
          setMessageId('duplicatedError');
          break;
        default:
          setMessageId('errorUnknown');
      }
    }
  }, [show, error]);
  if (show) {
    return (
      <Modal open={show} onCancel={() => onCancel()}>
        <div className="form-row" style={{ margin: '10px' }}>
          <p>{formatMessage({ id: messageId })}</p>
          {messageId === 'errorUnknown' && <p>{error?.toJSON().message}</p>}
        </div>
      </Modal>
    );
  }
  return null;
};

ErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,

  error: PropTypes.object.isRequired,
};

export default ErrorModal;
