import AccionaInput from '../../../../../components/AccionaInput';
import Step from '../../../../../components/Step';
import AccionaFormItem from '../../../../../components/AccionaFormItem';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';

const Cemex = ({ onFinish, preregister, onCancel, stepNumber }) => {
  const intl = useIntl();
  return (
    <Step
      stepNumber={stepNumber}
      title={intl.formatMessage({
        id: 'preregisterWizard.section.preregister.step015',
      })}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.preregister',
      })}
      content={
        <>
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="plant"
              state={{
                value: preregister.plant,
                status: 'success',
              }}
              disabled
            />
          </AccionaFormItem>
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="manufacture_time"
              state={{
                value: preregister.manufacture_time,
                status: 'success',
              }}
              disabled
            />
          </AccionaFormItem>
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="delivered"
              state={{
                value: preregister.delivered,
                status: 'success',
              }}
              disabled
            />
          </AccionaFormItem>
          <AccionaFormItem>
            <AccionaInput
              type="text"
              label="recipe"
              state={{
                value: preregister.recipe,
                status: 'success',
              }}
              disabled
            />
          </AccionaFormItem>
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish({ ...preregister });
      }}
    />
  );
};

Cemex.propTypes = {
  preregister: PropTypes.shape({
    manufacture_time: PropTypes.string,
    plant: PropTypes.string,
    recipe: PropTypes.string,
    delivered: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
};

Cemex.defaultProps = {
  preregister: {},
};

export default Cemex;
