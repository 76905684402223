import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AccionaTableBody from './AccionaTableBody';
import AccionaTableHeader from './AccionaTableHeader';
import AccionaTablePagination from './AccionaTablePagination';

const AccionaTable = ({
  initialFilters,
  getItems,
  entity,
  columns,
  actions,
  total,
  pageSize,
  items,
  onChange,
  deleted,
  pid,
}) => {
  const [pagination, setPagination] = useState({
    current: 1,
    total,
    pageSize,
  });
  const [filters, setFilters] = useState(initialFilters || '');
  const { current: currentPage, pageSize: currentPageSize } = pagination;

  useEffect(() => {
    if (filters) {
      getItems({
        size: currentPageSize,
        page: currentPage,
        filters: { project: pid, ...filters },
      });
    } else {
      getItems({
        size: currentPageSize,
        page: currentPage,
        filters: { project: pid },
      });
    }
  }, [
    currentPage,
    currentPageSize,
    getItems,
    pid,
    filters,
    columns.docket_number?.ascSort,
    deleted,
  ]);

  useEffect(() => {
    if (pagination.total !== total)
      setPagination((prev) => ({ ...prev, total }));
  }, [total, pagination]);

  useEffect(() => {
    const lastPage = Math.round(total / currentPageSize);
    if (
      items.length < currentPageSize &&
      total &&
      items.length < total &&
      currentPage !== !lastPage
    ) {
      getItems({
        size: currentPageSize,
        page: currentPage,
        filters: { pid: pid, ...filters },
      });
    }
  }, [
    currentPage,
    currentPageSize,
    filters,
    getItems,
    items.length,
    pid,
    total,
  ]);

  useEffect(() => {
    onChange({ pagination, filters });
  }, [pagination, filters, onChange]);

  return (
    <div className="card no-padding no-margin" id="AccionaTable">
      <div className="dataTables_wrapper no-footer">
        <table style={{ backgroundColor: '#fff' }}>
          <AccionaTableHeader
            columns={columns}
            selectedPage={pagination.current}
            entity={entity}
            setFilters={setFilters}
            setPagination={setPagination}
            total={total}
            filters={filters}
          />
          <AccionaTableBody
            bodyData={items}
            columns={columns}
            actions={actions}
          />
        </table>
        <AccionaTablePagination
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, { entity }) => ({
  items: state[entity].list,
  total: state[entity].total,
  deleted: state[entity].deleted,
  fetching: state[entity].fetching,
});

AccionaTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  getItems: PropTypes.func.isRequired,
  deleted: PropTypes.number,
  total: PropTypes.number,
  entity: PropTypes.string.isRequired,
  dateRows: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  columns: PropTypes.object,
  pageSize: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object),
  initialFilters: PropTypes.object,
  actions: PropTypes.func,
  pid: PropTypes.string.isRequired,
};

AccionaTable.defaultProps = {
  data: [],
  deleted: null,
  async: false,
  total: 0,
  fetching: false,
  pageSize: 5,
  items: [],
  dateRows: [],
  onChange: () => {},
  columns: {},
  actions: undefined,
  initialFilters: undefined,
};

export default connect(mapStateToProps, {})(AccionaTable);
