import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const AccionaTablePagination = ({ pagination, setPagination }) => {
  const [pageController, setPageController] = useState([1, 2, 3, 4, 5, 6, 7]);
  const [pageButtons, setPageButtons] = useState({
    previous: 'disabled',
    next: 'disabled',
  });

  useEffect(() => {
    if (pagination.total < pagination.pageSize) {
      setPageController([1]);
      setPageButtons({
        previous: 'disabled',
        next: 'disabled',
      });
    } else {
      const rest = Math.ceil(pagination.total / pagination.pageSize);
      const pageArray = [];
      const buttonsStatus = {
        previous: null,
        next: null,
      };

      if (rest <= 7) {
        for (let i = 0; i < rest; i++) {
          pageArray.push(i + 1);
        }
      } else {
        if (pagination.current <= 4) {
          for (let i = 0; i < rest; i++) {
            pageArray.push(i + 1);
          }
          pageArray[5] = '...';
          pageArray[6] = rest;
        } else if (pagination.current > rest - 4) {
          pageArray[0] = 1;
          pageArray[1] = '...';
          for (let i = rest - 4; i <= rest; i++) {
            pageArray.push(i);
          }
        } else {
          pageArray[0] = 1;
          pageArray[1] = '...';
          pageArray[2] = pagination.current - 1;
          pageArray[3] = pagination.current;
          pageArray[4] = pagination.current + 1;
          pageArray[5] = '...';
          pageArray[6] = rest;
        }
        pageArray.length = 7;
      }
      if (pagination.current === 1) buttonsStatus.previous = 'disabled';
      if (pagination.current === rest) buttonsStatus.next = 'disabled';
      setPageController(pageArray);
      setPageButtons(buttonsStatus);
    }
  }, [pagination]);

  const updatePage = (current) => {
    setPagination((prev) => ({ ...prev, current }));
  };

  return (
    <>
      <div
        className="dataTables_paginate paging_simple_numbers"
        id="AccionaTablePagination"
      >
        {/* eslint-disable-next-line*/}
        <a
          className={`paginate_button ${pageButtons.previous}`}
          onClick={
            pageButtons.previous === 'disabled'
              ? null
              : () => updatePage(pagination.current - 1)
          }
          id="PreviousBtn"
        >
          <FormattedMessage id="previous" />
        </a>
        {pageController.map((pageButton) => {
          if (pageButton === '...') {
            return (
              <span key="ellipsis" className="ellipsis">
                …
              </span>
            );
          } else if (pageButton === pagination.current) {
            //eslint-disable-next-line
            return (
              /* eslint-disable-next-line*/
              <a key={pageButton} className="paginate_button current">
                {pageButton}
              </a>
            );
          } else {
            //eslint-disable-next-line
            return (
              /* eslint-disable-next-line*/
              <a
                key={pageButton}
                className="paginate_button "
                onClick={() => updatePage(pageButton)}
              >
                {pageButton}
              </a>
            );
          }
        })}
        {/* eslint-disable-next-line*/}
        <a
          className={`paginate_button next ${pageButtons.next} `}
          onClick={
            pageButtons.next === 'disabled'
              ? null
              : () => updatePage(pagination.current + 1)
          }
          id="NextBtn"
        >
          <FormattedMessage id="next" />
        </a>
      </div>
    </>
  );
};

AccionaTablePagination.propTypes = {
  pagination: PropTypes.shape({
    total: PropTypes.number,
    pageSize: PropTypes.number,
    current: PropTypes.number,
  }).isRequired,
  setPagination: PropTypes.func.isRequired,
};

AccionaTablePagination.defaultProps = {};

export default AccionaTablePagination;
