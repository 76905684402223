import React from 'react';
import { Breadcrumb } from 'antd';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import styles from './Breadcrumbs.module.scss';
import { Link } from 'react-router-dom';

const { breadcrumbs } = styles;

const Breadcrumbs = () => {
  const intl = useIntl();
  const location = useLocation();
  const pathnameSplited = location.pathname.split('/').filter((item) => item);
  const items = useMemo(() => {
    return pathnameSplited
      .map((pathSplited) => {
        const breadcrumbsItems = [];
        if (pathSplited === 'projects')
          breadcrumbsItems.push({
            title: (
              <Link to="/app/projects">
                {intl.formatMessage({ id: 'projects' })}
              </Link>
            ),
          });

        if (pathSplited === 'app')
          breadcrumbsItems.push({
            title: (
              <Link to="/app/projects">
                {intl.formatMessage({ id: 'home' })}
              </Link>
            ),
          });

        if (pathSplited === 'preregister')
          breadcrumbsItems.push({
            title: intl.formatMessage({ id: 'preregistration' }),
          });

        if (pathSplited === 'preregisterList')
          breadcrumbsItems.push({
            title: intl.formatMessage({ id: 'preregisters' }),
          });

        return breadcrumbsItems;
      })
      .flat();
  }, [intl, pathnameSplited]);

  return (
    <div className={breadcrumbs}>
      <Breadcrumb items={items} separator=">" />
    </div>
  );
};

export default Breadcrumbs;
