import { addToCache } from '../../indexedDB/utils';
import { PreRegisterDocumentCache } from '../../models/PreRegisterDocument';
import { getQueryData } from '../../utils/parseFilters';
import axios from './api';
import { uploadFile } from './files';
import { getUrl, prmImgUploader } from './utils';

const endPoint = getUrl('api') + '/api/v2/PreDeliveryNotes';
const endPointCemex = 'https://api.ocr.dev.digiconcrete.es/api/v1/cemex?';

export const listPreDeliveryNote = async ({ page, size, filters }) => {
  // if (!filters.used) filters.used = false;
  // else if (filters.used === 1) filters.used = true;
  return axios
    .get(
      endPoint.concat(
        getQueryData(page, size, filters).replace('pid', 'project'),
      ),
      {
        timeout: 30000,
      },
    )
    .then((res) => res)
    .catch((error) => error.response);
};

export const deletePreDeliveryNote = (id) =>
  axios
    .delete(endPoint.concat(`/${id}`))
    .then((res) => res)
    .catch((error) => error.response);

export const getPreDeliveryNote = (id) =>
  axios
    .get(endPoint.concat(`/${id}`), { timeout: 30000 })
    .then((res) => res)
    .catch((error) => error.response);

export const savePreDeliveryNoteOnline = async (body) => {
  try {
    const preDN = new PreRegisterDocumentCache(body);
    await uploadFile(preDN.photo_element, 'delivery', preDN.getFileName());
    if (preDN.prm_data) {
      await (preDN.prm_data.right_appearance_img.length
        ? prmImgUploader(
            preDN.prm_data.right_appearance_img,
            'right_appearance',
            { docketNumber: preDN.docket_number, pid: preDN.project },
          )
        : 0);
      await (preDN.prm_data.limit_time_img.length
        ? prmImgUploader(preDN.prm_data.limit_time_img, 'limit_time', {
            docketNumber: preDN.docket_number,
            pid: preDN.project,
          })
        : 0);
      await (preDN.prm_data.added_substance_img.length
        ? prmImgUploader(
            preDN.prm_data.added_substance_img,
            'added_substance',
            { docketNumber: preDN.docket_number, pid: preDN.project },
          )
        : 0);
      await (preDN.prm_data.specimens_img?.length
        ? prmImgUploader(preDN.prm_data.specimens_img, 'specimens', {
            docketNumber: preDN.docket_number,
            pid: preDN.project,
          })
        : 0);
      await (preDN.prm_data.signature.length
        ? prmImgUploader(preDN.prm_data.signature, 'signature', {
            docketNumber: preDN.docket_number,
            pid: preDN.project,
          })
        : 0);
    }
    return axios.post(endPoint, preDN.toApi(), { timeout: 30000 });
  } catch (error) {
    console.error('savePreDeliveryNoteOnline', error);
    throw error;
  }
};

export const savePreDeliveryNote = async (body) => {
  try {
    const response = savePreDeliveryNoteOnline(body);
    return response;
  } catch (error) {
    console.error('savePreDeliveryNote', error);
    if (error.message === 'Network Error') {
      return await savePreDNOffline(body);
    }
    return error;
  }
};

export const savePreDNOffline = async (body) => {
  try {
    const preDN = await new PreRegisterDocumentCache(body).toJSON();
    const cache = await addToCache(preDN);
    return cache;
  } catch (error) {
    console.error('saveOffline', error);

    throw error;
  }
};

export const getPreDeliveryNoteCemex = ({ docketNumber, date }) => {
  return axios
    .get(endPointCemex.concat(`date=${date}&docketNumber=${docketNumber}`), {
      timeout: 30000,
    })
    .then((res) => res)
    .catch((error) => error.response);
};
