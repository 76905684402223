import React from 'react';
import { PropTypes } from 'prop-types';

const AccionaLabel = ({ children }) => {
  return (
    <span
      className="accionaLabel"
      style={{
        margin: '1px',
        paddingRight: '7px',
        paddingLeft: '7px',
        color: '#ff0000',
        background: '#fff1f0',
        borderStyle: 'solid',
        borderRadius: '2px',
        borderWidth: '1px',
        borderColor: '#ff0000',
        fontSize: '15px',
      }}
    >
      {' '}
      {children}
    </span>
  );
};

AccionaLabel.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AccionaLabel;
