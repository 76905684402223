import Step from '../../../../../components/Step';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './index.module.scss';
import PreregisterResume from '../../../common/PreregisterResume';
import PRMResume from '../../../common/PRMResume';
import NCResume from '../../../common/NCResume';

const { title } = styles;

const Resume = ({
  onFinish,
  preregister,
  onCancel,
  onCreatePRM,
  stepNumber,
}) => {
  const intl = useIntl();
  return (
    <Step
      isTheLastStep
      title={intl.formatMessage({ id: 'preregisterWizard.section.nc.step02' })}
      stepNumber={2}
      sectionTitle={intl.formatMessage({
        id: 'preregisterWizard.section.nc',
      })}
      content={
        <>
          <h4 className={title}>
            {intl.formatMessage({ id: `finishPreRegisterContent` })}
          </h4>
          <PreregisterResume preregister={preregister} />
          <PRMResume preregister={preregister} />
          <NCResume preregister={preregister} />
        </>
      }
      onBackButtonClick={() => {
        onCancel();
      }}
      onNextButtonClick={() => {
        onFinish();
      }}
    />
  );
};

Resume.propTypes = {
  preregister: PropTypes.shape({
    docket_number: PropTypes.string,
    arriving_date: PropTypes.string,
    arriving_time: PropTypes.string,
    start_discharging_time: PropTypes.string,
    finish_discharging_time: PropTypes.string,
    mash_control: PropTypes.bool,
    mash_control_extra: PropTypes.bool,
    specimens_number: PropTypes.number,
    slump: PropTypes.number,
    detailed_location: PropTypes.string,
    elements: PropTypes.string,
  }),
  onFinish: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  onCreatePRM: PropTypes.func.isRequired,
};

Resume.defaultProps = {
  preregister: {},
};

export default Resume;
