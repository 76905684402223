import { dateReg, dateReg2 } from '../utils/formRules';

export function haveValidKeys(objArray, keys) {
  if (!objArray) return false;

  for (let i in objArray) {
    for (let k in keys) {
      if (!objArray[i]) return false;
      if (!objArray[i][keys[k]]) return false;
    }
  }
  return true;
}

export function getPid(searchString) {
  return Number(searchString.match(/^\?pid=(\d+)/)[1]) || 1;
}

export function unnestingObjects(obj, nestedKey) {
  for (let key in obj) obj[key] = obj[key][nestedKey];
  return obj;
}

export function allAttrTrue(object) {
  for (const i in object) {
    if (!object[i].isSelected) return false;
  }
  return true;
}

export function allAttrAreValidated(object) {
  for (const i in object) {
    if (object[i]?.status === 'error') return false;
  }
  return true;
}
export function getValue(str, obj) {
  if (typeof obj === 'object' && obj !== null)
    return str.split('.').reduce((o, d) => o[d], obj);
}
export function docility(number, normative = 'EHE') {
  const limitAccordinNormative = normative === 'EHE' ? 55 : 45;
  if (number >= 0 && number <= 25) {
    return 'dry';
  } else if (number > 25 && number <= limitAccordinNormative) {
    return 'plastic';
  } else if (number > limitAccordinNormative && number <= 95) {
    return 'soft';
  } else if (number > 95 && number <= 155) {
    return 'fluid';
  } else if (number > 155 && number <= 210) {
    return 'liquid';
  }
}

export var normalize = (function () {
  var from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç',
    to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc',
    mapping = {};

  for (var i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  return function (str) {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret.join('');
  };
})();

export function normalizeTime(time) {
  var splittedTime = time.split(':');

  if (splittedTime.length === 3) return time;
  else if (splittedTime.length === 2) return time + ':00';
}

export function normalizeDate(date) {
  if (dateReg2.test(date)) return date;
  else if (dateReg.test(date)) {
    var splittedDate = date.split('/');

    if (String(splittedDate[2]).length < 4) {
      splittedDate[2] = '20' + splittedDate[2];
    }
    return splittedDate[2] + '-' + splittedDate[1] + '-' + splittedDate[0];
  }
}

export function denormalizeDate(date) {
  if (dateReg.test(date)) return date;
  else if (dateReg2.test(date)) {
    var splittedDate = date.split('-');

    if (String(splittedDate[0]).length < 4) {
      splittedDate[2] = '20' + splittedDate[2];
    }
    return splittedDate[2] + '/' + splittedDate[1] + '/' + splittedDate[0];
  }
}

export function getProject(pid) {
  const userProjects = JSON.parse(localStorage.getItem('projectsList'));
  const project = userProjects.find(
    (project) => project.id_project === parseInt(pid, 10),
  );
  return project;
}
