import React from 'react';
import Header from './Header';
import Breadcrumbs from './Breadcrumbs';

const TemplateHeader = () => {
  return (
    <>
      <Header />
      <Breadcrumbs />
    </>
  );
};

export default TemplateHeader;
